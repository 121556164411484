import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { StaticImage } from "gatsby-plugin-image"
import banner from '../images/about-banner.png'
import safety from '../images/about-safety.png'
import innovation from '../images/about-innovation.png'
import performance from '../images/about-performance.png'
import coreValues from '../images/about-cv.png'
import CE from '../images/about-ce.png'
import BSCI from '../images/about-bsci.png'
import OSHA from '../images/about-osha.png'
import ANSI from '../images/about-ansi.png'

export default function About() {
    return (
        <Layout>
            <Seo 
                title={'About Us | Certified Tools Supplier in UAE | Gazelle® Tools'}
                description={'Gazelle® is a brand based in the UAE that provides industrial tools, ladders, power tools, and safety gear. Our products are trusted by experts worldwide.'}
            />
            {/* banner */}
            <div className="w-full h-36 md:h-64 bg-secondary">
                <StaticImage src="../images/about-banner.png" className="w-full h-full object-cover" alt="About GAZELLE® Industrial" />
            </div>
            {/* About main text */}
            <div className="py-8 px-4 md:px-8 2xl:">
                <h1 className='text-xl text-primary pb-4'>About GAZELLE®</h1>
                <p className='md:text-lg pb-8'>GAZELLE® has a reputation for providing innovative solutions for customers who value reliability, accuracy, and safety on the job site. Whatever the task, our products, known for their ruggedness and rigid standards of quality, will help you do the job simply, safely, and with maximum performance. </p>
                <p className='md:text-lg pb-8'>Since our founding over two decades ago, we have been committed to the highest levels of safety and reliability, so you can carry out your essential tasks with confidence. At GAZELLE®, we have a clear, simple motto: to create products that work as hard as you do, and so we are driven to keep you safe, help you succeed, and equip you for maximum impact in your industry.</p>
                <p className='md:text-lg pb-8'>Our products are designed to meet the crucial needs of users in the construction, facility management, workshops, automotive, and general industrial applications. As innovative leaders, we aim to continue our rapid growth into new markets and new technologies with our electrical testing, safety at height, storage, and general operations tools solutions.</p>
            </div>
            {/* core values */}
            <div className="px-4 py-6 md:py-8 md:px-8 w-full bg-secondary-light">
                <div className="flex items-center justify-center gap-4 md:gap-8 w-full md:w-2/3 mx-auto">
                    <div className='md:order-last'>
                        <h3 className='text-xl text-primary pb-3 md:pb-2'>Core Values</h3>
                        <p className=''>At GAZELLE®, we take pride in setting the standard with our obsession with optimal safety, constant innovation, and top performance. These core values form the framework for our brand and help establish long-lasting partnerships with our customers.</p>
                    </div>
                    <img src={coreValues} className="h-24" alt="GAZELLE® Industrial Core Values" />
                </div>
            </div>
            {/* 3 column section */}
            <div className="py-8 px-4 md:px-8">
                <div className="grid grid-cols-1 md:grid-cols-3 md:text-center gap-y-7 gap-x-14">
                    <div>
                        <div className="flex items-center md:justify-center gap-2 pb-5">
                            <img src={safety} alt="GAZELLE® Industrial Safety" className='' />
                            <h3 className='text-xl text-primary '>Safety</h3>
                        </div>
                        <p className='md:px-6'>GAZELLE® raises industry standards by building advanced, solution-focused products to ensure our customers work confidently and securely. We are committed to maintaining a safe job site environment, and so our products go through a rigorous testing process to conform to various local and international safety standards to ensure your tools won’t fail on the job. GAZELLE® products are certified by international bodies such as OSHA, EN131, TUV, GS and Intertek.</p>
                    </div>
                    <div>
                        <div className="flex items-center md:justify-center gap-2 pb-5">
                            <img src={innovation} alt="GAZELLE® Industrial Innovation" />
                            <h3 className='text-xl text-primary '>Innovation</h3>
                        </div>
                        <p className='md:px-6'>At GAZELLE®, we endeavour to pursue innovation that creates high value for our customers and always stay one step ahead to deliver excellence. We thrive on new solutions and technological advancements with our product portfolio, giving our the customers competitive edge needed for operational excellence.</p>
                    </div>
                    <div>
                        <div className="flex items-center md:justify-center gap-2 pb-5">
                            <img src={performance} alt="GAZELLE® Industrial Performance" />
                            <h3 className='text-xl text-primary '>Performance</h3>
                        </div>
                        <p className='md:px-6'>We constantly strive to be the partner of choice by setting new standards of superior performance. Customers rely on GAZELLE® for premium quality and maximum productivity, and so we are dedicated to continually designing, testing and improving our products so that we remain the brand of choice for every professional looking for safety, reliability, and optimal performance on the job.</p>
                    </div>
                </div>
                {/* certification images */}
                <div className="pt-16 pb-6 flex justify-center items-center gap-2 md:gap-8">
                    <img className='h-12 md:h-20' src={CE} alt="CE Certification" />
                    <img className='h-12 md:h-20' src={BSCI} alt="BSCI Certification" />
                    <img className='h-12 md:h-20' src={OSHA} alt="OSHA Certification" />
                    <img className='h-12 md:h-20' src={ANSI} alt="ANSI Certification" />
                </div>
            </div>

        </Layout>
    )
}
